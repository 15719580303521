<div class="container">
    <mdb-card>
        <mdb-card-header class="banner white-text text-center py-4">
            <h5>
                <mdb-icon fas icon="unlock"></mdb-icon><strong> Login</strong>
            </h5>
        </mdb-card-header>
        <mdb-card-body class="px-lg-5 pt-0">
            <form class="text-center" style="color: #757575;">
                <div class="md-form">
                    <input type="text" id="username" name="username" [(ngModel)]="username" class="form-control" mdbInput>
                    <label for="username">Username</label>
                </div>
                <div class="md-form">
                    <input type="password" id="password" name="password" [(ngModel)]="password" class="form-control" mdbInput>
                    <label for="password">Password</label>
                </div>
                <div class="d-flex justify-content-around">
                </div>
                <button mdbBtn type="button" (click)="login()"class="blue darken-1" style="color: white;" rounded="true" mdbWavesEffect>Login</button>
            </form>
        </mdb-card-body>
    </mdb-card>
</div>