import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(public router: Router, private authenticationService: AuthenticationService, private toast: ToastService) { }

  ngOnInit(): void {
  }

  hideNavbar():boolean{
    if(this.router.url == '/login')
      return false;
    else 
      return true;
  }

  userLogout() {
    this.authenticationService.logout();
    setTimeout(() => this.toast.success("Logout"));
    this.router.navigate(['/login']);
  }

}
