import { RestEntity } from 'src/utils/rest-template';

export class Customer extends RestEntity {
    public ID: number;
    public NOME: string;
    public TIPO_LICENZA: string;
    public VERSIONE: string;
    public APP_TRAFI: boolean;
    public APP_SELF: boolean;
    public APP_TRAFI_P: boolean;
    public APP_SELF_P: boolean;
    public UTENTI_CONNESSI: number;
    public DATA_INIZIO: Date;
    public BLOCCATO: boolean;
    public SUPPORTO_ID: number;
    public SUPPORTO_INIZIO: Date;
    public SUPPORTO_FINE: Date;
    public PAGATO: boolean;

    constructor($id?: number, $nome?: string, $versione?:string, $tipoLicenza?: string, $appTrafi?: boolean, $appSelf?: boolean, $appTrafiP?: boolean, $appSelfP?: boolean, $utentiConnessi?: number, $dataInizio?: Date, $bloccato?: boolean, $supportoId?: number, $supportoInizio?: Date, $supportoFine?: Date, $pagato?: boolean) {
        super();
        this.ID = $id;
        this.NOME = $nome;
        this.VERSIONE = $versione;
        this.TIPO_LICENZA = $tipoLicenza;
        this.APP_TRAFI = $appTrafi;
        this.APP_TRAFI_P = $appTrafiP;
        this.APP_SELF = $appSelf;
        this.APP_SELF_P = $appSelfP;
        this.UTENTI_CONNESSI = $utentiConnessi;
        this.DATA_INIZIO = $dataInizio;
        this.BLOCCATO = $bloccato;
        this.SUPPORTO_ID = $supportoId;
        this.SUPPORTO_INIZIO = $supportoInizio;
        this.SUPPORTO_FINE = $supportoFine;
        this.PAGATO = $pagato;
    }
}