<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel"><mdb-icon fas icon="key"></mdb-icon> Chiave licenza</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-8">
            <p class="wrap-text">
                {{token.token}}
            </p>
        </div>
        <div class="col-4 text-right">
            <button mdbBtn type="button" size="sm" color="primary" (click)="copyToken()" rounded="true" mdbTooltip="Copia token" mdbWavesEffect><mdb-icon fas icon="copy"></mdb-icon> Copia</button> 
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mdbBtn type="button" color="danger" (click)="modalRef.hide()" rounded="true" mdbWavesEffect><mdb-icon fas icon="sign-out-alt"></mdb-icon> Esci</button>
</div>