import { Component, OnInit } from '@angular/core';
import { Customer } from '../models/customer.model';
import { ToastService, IMyOptions } from 'ng-uikit-pro-standard';
import { RestService } from '../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss']
})
export class EditLicenseComponent implements OnInit {
  customers: Array<Customer>;
  clientId: string;
  customer: Customer;
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  options = [
    { value: 'STA', label: 'Standard' },
    { value: 'PRO', label: 'Professional' },
    { value: 'ENT', label: 'Enterprise' }
  ];
  constructor( private toast: ToastService, private router: Router, private activatedRouter: ActivatedRoute, private restService: RestService) {
    this.customers = new Array<Customer>();
    this.customer = new Customer();
  }

  async ngOnInit() {
    await this.getUser();
  }

  async getUser() {
    this.customers = await this.restService.customer.getAll();
    this.customers.forEach(customer =>{
      if(customer.ID.toString() == this.activatedRouter.snapshot.params['id'])
        this.customer = customer;
    });
  }

  async editLicense() {
    let result: any = await this.restService.customer.editLicense(this.customer);
    if (result.ID) {
      setTimeout(() => this.toast.success("Licenza "+ this.customer.NOME +" modificata"));
      this.router.navigate(['/list-licenses']);
    }
    else
      setTimeout(() => this.toast.error("Errore"));
  }

}
