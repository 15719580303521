<div class="modal-header text-center">
    <button type="button" class="close pull-right" (click)="modalRef.hide()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel"><mdb-icon fas icon="user"></mdb-icon> <b> {{customer.NOME}}</b></h4>
    <h4 class="modal-title w-100"><mdb-icon fas icon="calendar-alt"></mdb-icon> <b> {{customer.DATA_INIZIO | date}}</b></h4>
</div>
<div class="modal-body">
    <div class="row text-center mb-1">
        <div class="col-12">
            <h5><mdb-icon fas icon="info-circle"></mdb-icon><b> Info licenza</b></h5>
        </div>
    </div>
    <div class="row text-center mb-3">
        <div class="col-6">
            <b><mdb-icon fas icon="suitcase"></mdb-icon> Licenza: {{customer.TIPO_LICENZA}}</b>
        </div>
        <div class="col-6">
            <mdb-icon fas icon="users"></mdb-icon><b> Utenti: {{customer.UTENTI_CONNESSI}}</b>
        </div>
    </div>
    <hr>
    <div class="row text-center mb-1">
        <div class="col-12">
            <h5><b><mdb-icon fas icon="laptop"></mdb-icon> Applicazioni</b></h5>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-3">
            <b>Trafi</b>
        </div>
        <div class="col-3">
            <b>Trafi Piccoli</b>
        </div>
        <div class="col-3">
            <b>Self</b> 
        </div>
        <div class="col-3">
            <b>Self piccole</b>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-3">
            <div>
                <mdb-icon *ngIf="customer.APP_TRAFI" class="green-text" size="lg" fas icon="check"></mdb-icon>
                <mdb-icon *ngIf="!customer.APP_TRAFI" class="red-text" size="lg" fas icon="times"></mdb-icon>
            </div>
        </div>
        <div class="col-3">
            <div>
                <mdb-icon *ngIf="customer.APP_TRAFI_P" class="green-text" size="lg" fas icon="check"></mdb-icon>
                <mdb-icon *ngIf="!customer.APP_TRAFI_P" class="red-text" size="lg" fas icon="times"></mdb-icon>
            </div>
        </div>
        <div class="col-3">
            <div>
                <mdb-icon *ngIf="customer.APP_SELF" class="green-text" size="lg" fas icon="check"></mdb-icon>
                <mdb-icon *ngIf="!customer.APP_SELF" class="red-text" size="lg" fas icon="times"></mdb-icon>
            </div>
        </div>
        <div class="col-3">
            <div>
                <mdb-icon *ngIf="customer.APP_SELF_P" class="green-text" size="lg" fas icon="check"></mdb-icon>
                <mdb-icon *ngIf="!customer.APP_SELF_P" class="red-text" size="lg" fas icon="times"></mdb-icon>
            </div>
        </div>
    </div>
    <hr>
    <div class="row text-center mb-1">
        <div class="col-12">
            <h5><mdb-icon fas icon="question-circle"></mdb-icon> <b> Supporto</b></h5>
        </div>
    </div>
    <div class="row text-center mb-3">
        <div class="col-6">
            <b><mdb-icon fas icon="calendar-check"></mdb-icon> Inizio: {{customer.SUPPORTO_INIZIO | date}}</b>
        </div>
        <div class="col-6">
            <b><mdb-icon fas icon="calendar-times"></mdb-icon> Fine: {{customer.SUPPORTO_FINE | date}}</b>
        </div>
    </div>
    <hr>
    <div class="row text-center mb-1">
        <div class="col-12">
            <h5><b><mdb-icon fas icon="edit"></mdb-icon> Stato licenza</b></h5>
        </div>
    </div>
    <div class="row text-center mb-3">
        <div class="col-6">
            <b><mdb-icon fas icon="credit-card"></mdb-icon> Pagamento: <mdb-icon *ngIf="customer.PAGATO" class="green-text" size="lg" fas icon="check"></mdb-icon>
                <mdb-icon *ngIf="!customer.PAGATO" class="red-text" size="lg" fas icon="times"></mdb-icon></b>
        </div>
        <div class="col-6">
            <b><mdb-icon fas icon="shield-alt"></mdb-icon> Attivo: <mdb-icon *ngIf="!customer.BLOCCATO" class="green-text" size="lg" fas icon="check"></mdb-icon>
                <mdb-icon *ngIf="customer.BLOCCATO" class="red-text" size="lg" fas icon="times"></mdb-icon></b>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mdbBtn type="button" color="danger" rounded="true" (click)="modalRef.hide()" mdbWavesEffect><mdb-icon fas icon="sign-out-alt"></mdb-icon> Esci</button>
</div>