import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/utils/app-config';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService {

    constructor(private router: Router, private http: HttpClient, private cookieSerivice: CookieService) {
    }

    async login(username: string, password: string) {
        const body = new HttpParams()
            .set('username', username)
            .set('password', password);
        return new Promise(async (resolve, reject) => {
            let result: any = null;
            result = await (this.http.post(AppConfig.API_BASE_URL_PROD + 'token', body.toString(), {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }).toPromise());
            resolve(result);
        });
    }

    setCookie(authRes: any) {
        this.cookieSerivice.set('SESSION-ID', authRes.Token,  authRes.exp);
    }

    getToken() {
        return this.cookieSerivice.get('SESSION-ID');
    }

    logout() {
        this.cookieSerivice.delete('SESSION-ID');
    }
   
}