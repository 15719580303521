
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { RoutingModule } from './routing/routing.module';
import { LicenseListComponent } from './license-list/license-list.component';
import { AddLicenseComponent } from './add-license/add-license.component';
import { AuthenticationService } from './services/auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { ModalGetTokenComponent } from './modals/modal-get-token/modal-get-token.component';
import { ModalService } from './services/modal.service';
import { ModalGetCustomerComponent } from './modals/modal-get-customer/modal-get-customer.component';
import { EditLicenseComponent } from './edit-license/edit-license.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    LicenseListComponent,
    AddLicenseComponent,
    ModalGetTokenComponent,
    ModalGetCustomerComponent,
    EditLicenseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }),
    RoutingModule
  ],
  providers: [MDBSpinningPreloader, AuthenticationService, CookieService, ModalService],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
