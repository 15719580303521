import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { AppConfig } from './app-config';
import { Token } from 'src/app/models/token.model';
export class RestEntity {
    id: string;
}

export class RestTemplate<E extends RestEntity> {
    //protected static API_URL = '';
    protected static API_URL = AppConfig.API_BASE_URL_PROD;
    headers: HttpHeaders;

    constructor(protected resource: string, protected http: HttpClient, protected authenticationService: AuthenticationService) {
        this.headers = new HttpHeaders({
            'Authorization': 'Bearer '+this.authenticationService.getToken()
        })
    }

    async deleteById(id: string) {
        let result: any = null;
        if (id != null)
            return new Promise(async (resolve, reject) => {
                result = await (this.http.delete(RestTemplate.API_URL + this.resource + "/" + id).toPromise());
                resolve(result.Item);
            });
    }

    async createLicense(entity: E) {
        return new Promise(async (resolve, reject) => {
            let result = await (this.http.post(RestTemplate.API_URL + 'data/clienti', entity, {headers: this.headers}).toPromise());
            resolve(result);
        });
    }

    async editLicense(entity: E) {
        return new Promise(async (resolve, reject) => {
            let result = await (this.http.put(RestTemplate.API_URL + 'data/clienti', entity, {headers: this.headers}).toPromise());
            resolve(result);
        });
    }

    async getById(id: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            let element: Token = await (this.http.get<Token>(RestTemplate.API_URL + 'license/get/' + id, {headers: this.headers}).toPromise());
            resolve(element.token);
        });
    }

    async getAll(): Promise<E[]> {
        return new Promise<E[]>(async (resolve, reject) => {
            let response: any = await (this.http.get<E[]>(RestTemplate.API_URL + 'data' + '/' + this.resource, {headers: this.headers}).toPromise());
            resolve(response);
        });
    }
}