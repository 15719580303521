<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="h1 py-3 font-weight-semi-bold text-center">Modifica Licenza</h1>
        </div>
        <div class="col-4">
            <div class="row text-left">
                <div class="col-8">
                    <div class="md-form">
                        <input mdbInput type="text" id="name" [(ngModel)]="customer.NOME" class="form-control">
                        <label for="name" class="">Nome</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row text-center">
                <div class="col-8">
                    <div class="md-form">
                        <mdb-select [options]="options" [(ngModel)]="customer.TIPO_LICENZA"
                        placeholder="Tipo Licenza"></mdb-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row text-center">
                <div class="col-8">
                    <div class="md-form">
                        <input mdbInput type="text" id="versione" [(ngModel)]="customer.VERSIONE" class="form-control">
                        <label for="versione" class="">Versione</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row text-center mt-3">
      <div class="col-12 mb-3">
          <h4><b>Applicazioni</b></h4>
      </div>
    </div>
    <div class="row">
        <div class="col-3">
            <mdb-checkbox [(ngModel)]="customer.APP_TRAFI">Trafi</mdb-checkbox>
        </div>
        <div class="col-3">
            <mdb-checkbox [(ngModel)]="customer.APP_TRAFI_P">Trafi Piccoli</mdb-checkbox>
        </div>
        <div class="col-3">
            <mdb-checkbox [(ngModel)]="customer.APP_SELF">Self</mdb-checkbox>
        </div>
        <div class="col-3">
            <mdb-checkbox [(ngModel)]="customer.APP_SELF_P">Self Piccole</mdb-checkbox>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <div class="row text-center">
                <div class="col-8">
                    <mdb-date-picker name="mydate" label="Data inizio" id="dataInizio" [options]="myDatePickerOptions"
                        [placeholder]="'Data inizio'" [(ngModel)]="customer.DATA_INIZIO" required></mdb-date-picker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row text-center">
                <div class="col-8">
                    <div class="md-form">
                        <input mdbInput type="number" id="tipo" [(ngModel)]="customer.UTENTI_CONNESSI"
                            class="form-control">
                        <label for="tipo" class="">Numero utenti</label>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-6">
            <div class="row text-center">
                <div class="col-8">
                    <mdb-date-picker name="mydate" label="Supporto inizio" id="supportoInizio"
                        [options]="myDatePickerOptions" [placeholder]="'Data inizio'"
                        [(ngModel)]="customer.SUPPORTO_INIZIO" required></mdb-date-picker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row text-center">
                <div class="col-8">
                    <div class="md-form">
                        <mdb-date-picker name="mydate" label="Supporto fine" id="supportoFine"
                            [options]="myDatePickerOptions" [placeholder]="'Data inizio'"
                            [(ngModel)]="customer.SUPPORTO_FINE" required></mdb-date-picker>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="row text-center">
                <div class="col-8">
                    <mdb-checkbox [(ngModel)]="customer.PAGATO">Pagato</mdb-checkbox>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row text-center">
                <div class="col-8">
                    <mdb-checkbox [(ngModel)]="customer.BLOCCATO">Bloccato</mdb-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-12">
            <button (click)="editLicense()" [disabled]="false" mdbBtn type="button" color="success" rounded="true" mdbWavesEffect>
                <mdb-icon fas icon="plus"></mdb-icon> Modifica
            </button>
        </div>
    </div>
</div>