<!--Double navigation-->
<header *ngIf="hideNavbar()">

    <!-- Sidebar navigation -->
    <mdb-side-nav #sidenav class="blue darken-1" [fixed]="false">

        <mdb-navbar-brand>
            <li>
                <div class="logo-wrapper waves-light">
                    <a href="#">
                        <img src="../assets/img/Logo-zanini.png" width="200px" class="img-fluid flex-center">
                    </a>
                </div>
            </li>
        </mdb-navbar-brand>

        <links>
            <!--Social-->
            <li>
                <ul class="social" style="height: 85px;">
   
                </ul>
            </li>
            <!--/Social-->
            <!-- Side navigation links -->
            <li>
                <a href="#/add-license" style="color: white;" mdbWavesEffect><mdb-icon fas icon="plus"></mdb-icon> Nuova licenza</a>
            </li>
            <li>
                <a href="#/list-licenses" style="color: white;" mdbWavesEffect><mdb-icon fas icon="list"></mdb-icon> Lista licenze</a>
            </li>
            <li>
                <a (click)="userLogout()" style="color: white;" mdbWavesEffect><mdb-icon fas icon="sign-out-alt"></mdb-icon> Esci</a>
            </li>
            <!--/. Side navigation links -->
        </links>
        <div class="sidenav-bg mask-strong"></div>
    </mdb-side-nav>
    <!--/. Sidebar navigation -->

    <!-- Navbar -->
    <mdb-navbar
        SideClass="navbar navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav blue darken-1 fixed-top scrolling-navbar"
        [containerInside]="false" style="color: white; margin: 5px;">

        <navlinks class="navbar-container">
            <!-- SideNav slide-out button -->
            <div class="">
                <a (click)="sidenav.show()" class="button-collapse-non-fixed hidden-nav mx-0">
                    <mdb-icon fas icon="bars"></mdb-icon>
                </a>
            </div>
            <!--/. SideNav slide-out button -->
        </navlinks>

        <mdb-navbar-brand>
            <!-- Breadcrumb-->
            <div class="breadcrumbs breadcrumbs-non-fixed breadcrumb-dn mr-auto title">
                <p>Clever License</p>
            </div>
            <!--/. Breadcrumb-->
        </mdb-navbar-brand>

        <navlinks>
        </navlinks>
    </mdb-navbar>
    <!--/. Navbar -->

</header>
<!--/.Double navigation-->

<!--Main Layout-->
<main>
    <router-outlet></router-outlet>
</main>
<!--/Main layout-->