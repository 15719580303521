import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';
import { Customer } from '../models/customer.model';
import { RestTemplate } from 'src/utils/rest-template';

@Injectable({
    providedIn: 'root'
})
export class RestService {
    public customer = new RestTemplate<Customer>("clienti", this.http, this.authenticationService);
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }  
}