import { Component, OnInit } from '@angular/core';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { ModalService } from 'src/app/services/modal.service';
import { RestService } from 'src/app/services/rest.service';
import { Customer } from 'src/app/models/customer.model';
import { Constants } from 'src/utils/constant';

@Component({
  selector: 'app-modal-get-customer',
  templateUrl: './modal-get-customer.component.html',
  styleUrls: ['./modal-get-customer.component.scss']
})
export class ModalGetCustomerComponent implements OnInit {
  customers: Array<Customer>;
  clientId: string;
  customer: Customer;
  constructor(public modalRef: MDBModalRef, private toast: ToastService, private modalService: ModalService, private restService: RestService) {
    this.customers = new Array<Customer>();
    this.customer = new Customer();
  }

  async ngOnInit() {
    await this.getUser();
  }

  async getUser() {
    await this.modalService.sendClientId.subscribe(async (clientId: string) => {
      this.clientId = clientId;
    });
    this.customers = await this.restService.customer.getAll();
    this.customers.forEach(customer =>{
      if(customer.ID.toString() == this.clientId)
        this.customer = customer;
    });
    this.versionLicense();
  }

  versionLicense() {
    if(!this.customer)
      return;
    if(this.customer.TIPO_LICENZA == 'ENT')
      this.customer.TIPO_LICENZA = Constants.tipoLicenza.ENT;
    else if(this.customer.TIPO_LICENZA == 'PRO')
      this.customer.TIPO_LICENZA = Constants.tipoLicenza.PRO;
    else if(this.customer.TIPO_LICENZA == 'STA')
      this.customer.TIPO_LICENZA = Constants.tipoLicenza.STA;
  }

  ngOnDestroy() {
  }

}
