import { Component, OnInit } from '@angular/core';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { ModalService } from 'src/app/services/modal.service';
import { RestService } from 'src/app/services/rest.service';
import { Customer } from 'src/app/models/customer.model';
import { Token } from 'src/app/models/token.model';



@Component({
  selector: 'app-modal-get-token',
  templateUrl: './modal-get-token.component.html',
  styleUrls: ['./modal-get-token.component.scss']
})
export class ModalGetTokenComponent implements OnInit {
  token: Token;
  clientId: string;
  customer: Customer
  constructor(public modalRef: MDBModalRef, private toast: ToastService, private modalService: ModalService, private restService: RestService) {
    this.token = new Token();
    this.customer = new Customer();
  }

  async ngOnInit() {
    await this.getId();
  }

  async getId() {
    await this.modalService.sendClientId.subscribe(async (clientId: string) => {
      this.clientId = clientId;
    });
    this.token.token = await this.restService.customer.getById(this.clientId);
  }

  ngOnDestroy() {
  }

  confirmDelete() {
    this.modalService.confirmDelete.emit(true);
    this.modalRef.hide();
  }

  copyToken() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.token.token;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(
      () => this.toast.success("Copiato")
    );
  }

}
