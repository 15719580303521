<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="h1 py-3 font-weight-semi-bold text-center">Lista Licenze</h1>
        </div>
        <div class="col-md-8 mx-auto">
            <div class="md-form">
                <input type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchItems()"
                    id="search-input" mdbInput>
                <label for="search-input">Cerca</label>
            </div>
        </div>
        <table mdbTable #tableEl="mdbTable" hover="true" striped="true" class="z-depth-1 mt-3 mb-5">
            <thead class="sticky-top">
                <tr>
                    <th *ngFor="let head of headElements; let i = index" class="header-table" [mdbTableSort]="licenses"
                        [sortBy]="headElements[i]" scope="col">{{head | titlecase}}
                    </th>
                </tr>
            </thead>

            <tbody #row >
                <tr mdbTableCol *ngFor="let client of clients; let i = index" style="cursor: pointer;">
                    <td (click)="getClient(client.ID)" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.NOME}}</td>
                    <td (click)="getClient(client.ID)" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.TIPO_LICENZA}}</td>
                    <td (click)="getClient(client.ID)" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.UTENTI_CONNESSI}}</td>
                    <td (click)="getClient(client.ID)" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                            class="row-align">{{client.APP_TRAFI}}</td>
                    <td (click)="getClient(client.ID)" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.APP_TRAFI_P}}</td>
                    <td (click)="getClient(client.ID)"*ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.APP_SELF}}</td>
                    <td (click)="getClient(client.ID)"*ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.APP_SELF_P}}</td>
                    <td (click)="getClient(client.ID)"*ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">{{client.VERSIONE}}</td>
                    <td (click)="getClient(client.ID)" *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"class="row-align">
                        <mdb-icon *ngIf="!client.BLOCCATO" class="green-text" size="lg" fas icon="check"></mdb-icon>
                        <mdb-icon *ngIf="client.BLOCCATO" class="red-text" size="lg" fas icon="times"></mdb-icon>
                    </td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        class="row-align">
                        <a mdbBtn floating="true" (click)="editLicense(client.ID)" size="sm" color="success" mdbTooltip="Modifica licenza" placement="top"
                            mdbWavesEffect>
                            <mdb-icon fas icon="edit"></mdb-icon>
                        </a>
                        <a mdbBtn floating="true" size="sm" color="danger" mdbTooltip="Revoca licenza" placement="top"
                            mdbWavesEffect>
                            <mdb-icon fas icon="times"></mdb-icon>
                        </a>
                        <a mdbBtn floating="true" size="sm" (click)="downloadLicense(client.ID)" color="deep-purple" mdbTooltip="Download" placement="top"
                            mdbWavesEffect>
                            <mdb-icon fas icon="download"></mdb-icon>
                        </a>              
                        <a mdbBtn floating="true" (click)="getToken(client.ID)" size="sm" color="info" mdbTooltip="Mostra token" placement="top"
                            mdbWavesEffect>
                            <mdb-icon fas icon="key"></mdb-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
            <tfoot class="grey lighten-5 w-100">
                <tr>
                    <td colspan="12">
                        <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="licenses">
                        </mdb-table-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>