import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent, MDBModalRef, ToastService, MDBModalService } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { Customer } from '../models/customer.model';
import { RestService } from '../services/rest.service';
import { ModalGetTokenComponent } from '../modals/modal-get-token/modal-get-token.component';
import { ModalService } from '../services/modal.service';
import { ModalGetCustomerComponent } from '../modals/modal-get-customer/modal-get-customer.component';
import { Token } from 'src/app/models/token.model';

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss']
})
export class LicenseListComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;
  headElements = ['NOME', 'Tipo Licenza','Numero utenti' ,'Trafi', 'Trafi Piccoli', 'Self', 'Self Piccole', 'Vers', 'Attivo', 'Azioni'];
  modalRef: MDBModalRef;
  searchText: string = '';
  previous: string;
  clients = new Array<Customer>();
  customer: Customer;
  customers: Array<Customer>;
  cacheClients = new Array<Customer>();
  licenses = new Array<any>();
  token: Token;
  maxVisibleItems: number = 10;
  constructor(private mdbModalService: MDBModalService, private modalService: ModalService, private cdRef: ChangeDetectorRef, private router: Router, private toast: ToastService, private restService: RestService) {
    this.customers = new Array<Customer>();
    this.customer = new Customer();
    this.token = new Token();
  }

  async getAllLicense() {
  }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  async downloadLicense (id: string) {
    let text = "";
    let filename = "license-"+Math.floor(Math.random() * 10000)+".ini";
    this.customers = await this.restService.customer.getAll();
    this.customers.forEach(customer =>{
      if(customer.ID.toString() == id)
        this.customer = customer;
    });
    this.token.token = await this.restService.customer.getById(id);
    text = "[Info]\nName="+this.customer.NOME+"\nToken="+this.token.token+"\nVersion="+this.customer.VERSIONE;
    this.download(filename, text);
  }

  async ngOnInit() {
    this.clients = await this.restService.customer.getAll();
    this.cacheClients = this.clients;
    this.mdbTable.setDataSource(this.clients);
    this.previous = this.mdbTable.getDataSource();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  async getToken(clientId: string) {
    let modalToken = new MDBModalRef();
    modalToken = this.mdbModalService.show(ModalGetTokenComponent);
    this.modalService.sendClientId.emit(clientId);
  }
  async getClient(clientId: string) {
    let modalToken = new MDBModalRef();
    modalToken = this.mdbModalService.show(ModalGetCustomerComponent);
    this.modalService.sendClientId.emit(clientId);
  }

  editLicense(id: string) {
    this.router.navigate(['edit-license/',id ]);
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.clients = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.clients = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    if (this.searchText.length == 0) {
      this.mdbTable.setDataSource(this.cacheClients);
      this.clients = this.cacheClients;
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

}
