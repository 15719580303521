import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { AddLicenseComponent } from '../add-license/add-license.component';
import { LicenseListComponent } from '../license-list/license-list.component';
import { EditLicenseComponent } from '../edit-license/edit-license.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'list-licenses', component: LicenseListComponent },
  { path: 'add-license', component: AddLicenseComponent },
  { path: 'edit-license/:id', component: EditLicenseComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
