import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;

  constructor(private router: Router, private authenticationService: AuthenticationService, private toast: ToastService) { }

  ngOnInit(): void {
  }

  validationLogin(): boolean {
    return true;
  }

  async login() {
    let response: any = await this.authenticationService.login(this.username, this.password);
    if(response.Token){
      setTimeout(() => this.toast.success("Autenticazione riuscita"));
      this.authenticationService.setCookie(response);
      this.router.navigate(['/list-licenses']);
    }
    else {
      setTimeout(() => this.toast.error("Credenziali non valide"));
    }
  }

}
