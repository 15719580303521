import { Component, OnInit } from '@angular/core';
import { Customer } from '../models/customer.model';
import { IMyOptions, ToastService } from 'ng-uikit-pro-standard';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../services/rest.service';

@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html',
  styleUrls: ['./add-license.component.scss']
})
export class AddLicenseComponent implements OnInit {
  clientId: string;
  customer: Customer;
  options = [
    { value: 'STA', label: 'Standard' },
    { value: 'PRO', label: 'Professional' },
    { value: 'ENT', label: 'Enterprise' }
  ];
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  constructor(private toast: ToastService, private router: Router, private restService: RestService) {
    this.customer = new Customer();
    this.customer.APP_SELF = false;
    this.customer.APP_SELF_P = false;
    this.customer.APP_TRAFI = false;
    this.customer.APP_TRAFI_P = false;
    this.customer.BLOCCATO = false;
    this.customer.PAGATO = false;
  }
  ngOnInit(): void {
  }

  async createLicense() {
    let result: any = await this.restService.customer.createLicense(this.customer);
    if (result.ID) {
      setTimeout(() => this.toast.success("Nuova licenza aggiunta"));
      this.router.navigate(['/list-licenses']);
    }
    else
      setTimeout(() => this.toast.error("Errore"));
  }

}
